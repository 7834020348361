<!-- eslint-disable vue/no-mutating-props -->
<template>
  <card>
    <h5 slot="header" class="title">Карточка пользователя</h5>
    <div class="row">
      <div class="col-md-5 pr-md-1">
        <base-input label="Логин" placeholder="Логин" v-model="model.login">
        </base-input>
      </div>
      <div class="col-md-3 px-md-1">
        <base-input v-if="model.id === null" label="Пароль" placeholder="Пароль" v-model="model.password">
        </base-input>
      </div>
      <div class="col-md-4 pl-md-1">
        <div class="form-group">
          <label class="control-label">Роль</label>
          <v-select :searchable="false" :clearable="false" v-model="model.role" :reduce="role => role.id" :options="roles"></v-select>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="table-responsive">
          <base-table :data="table.data" :columns="table.columns" :actions="table.actions" :editAction="editUserShop"
            :deleteAction="deleteUserFromShop" thead-classes="text-primary">
          </base-table>
        </div>
        <base-button v-if="model.role == 3" slot="footer" type="secondary" fill @click="addUserToShopModal = true">Привязать к магазину</base-button>
      </div>
    </div>
    <base-button slot="footer" type="primary" fill @click="save">Сохранить</base-button>
    <base-button class="ml-2" v-if="model.id !== null" slot="footer" type="secondary" fill
      @click="changePasswordModal = true">Изменить пароль</base-button>
    <modal :show.sync="changePasswordModal" class="modal-change-password" id="changePasswordModal" :centered="false"
      :show-close="true">
      <base-input type="password" minlength="8" label="Старый пароль" placeholder="Старый пароль"
        v-model="changePasswordModel.old_password">
      </base-input>
      <base-input type="password" minlength="8" label="Новый пароль" placeholder="Новый пароль"
        v-model="changePasswordModel.new_password">
      </base-input>
      <base-input type="password" minlength="8" label="Новый пароль повторно" placeholder="Новый пароль повторно"
        v-model="new_password2">
      </base-input>
      <base-button slot="footer" type="submit" fill @click="changePassword" :disabled="loading">Сменить
        пароль</base-button>
      <div class="alert alert-warning alert-dismissible fade show mt-5 d-none" role="alert" id="alert_1">
        <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
      </div>
    </modal>
    <modal :show.sync="addUserToShopModal" class="modal-add-user-to-shop" id="addUserToShopModal" :centered="false"
      :show-close="true">
      <label class="control-label">Магазин</label>
      <v-select :clearable="false" placeholder="Выберите магазин" v-model="addToShop.shop_id" :options="org_list" :disabled="disabled == 1"></v-select>
      <label class="control-label">Роль</label>
      <v-select :searchable="false" :clearable="false" v-model="addToShop.role" :reduce="role => role.id" :options="access_roles"></v-select>
      <base-button slot="footer" type="submit" fill @click="addUserToShop" :disabled="loading">Привязать к магазину</base-button>
      <div class="alert alert-warning alert-dismissible fade show mt-5 d-none" role="alert" id="alert_1">
        <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
      </div>
    </modal>
    <modal :show.sync="alertModal" class="modal-alert" id="alertModal" :centered="false"
      :show-close="true">
      <div>{{ alert_content }}</div>
      <base-button slot="footer" type="submit" fill @click="alertModal = false" :disabled="loading">Ок</base-button>
      <div class="alert alert-warning alert-dismissible fade show mt-5 d-none" role="alert" id="alert_1">
        <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
      </div>
    </modal>
  </card>
</template>
<script>
import UserService from '../services/user.service';
import ChangePassword from '../models/change_password';
import AddToShop from '../models/add_to_shop';
import User from '../models/user';
import Modal from '@/components/Modal';
import { BaseTable } from "@/components";

export default {
  name: 'visitor-card',
  components: {
    Modal,
    BaseTable
  },
  props: {
    id: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      alert_content: '',
      alertModal: false,
      addToShop: new AddToShop(null, null),
      model: new User(null, '', '', null, []),
      changePasswordModal: false,
      addUserToShopModal: false,
      changePasswordModel: new ChangePassword(this.id, '', ''),
      roles: [{'id': 1, 'label': 'Администратор'}, {'id': 2, 'label': 'Закупщик'}, {'id': 3, 'label': 'Пользователь'}],
      access_roles: [{'id': 1, 'label': 'Администратор'}, {'id': 2, 'label': 'Менеджер'}],
      loading: false,
      table: {
        title: "Магазины",
        columns: ["Магазин", "Роль"],
        actions: ["edit", "delete"],
        data: []
      },
      new_password2: ''
    };
  },
  mounted() {
    this.loadData()
  },
  methods: {
    hasValue(item, column) {
      return item[column.toLowerCase()] !== "undefined";
    },
    loadData() {
      if (this.id !== '') {
        UserService.get(this.id).then(
          response => {
            this.model = new User(response.data['id'], response.data['login'], response.data['password'], response.data['role']);
          },
          error => {
            this.alert_content =
              (error.response && error.response.data) ||
              error.message ||
              error.toString();
            this.alertModal = true;
          }
        );
      }
    },
    save() {
      UserService.save(this.model).then(
        response => {
          this.$router.push('/users');
        },
        error => {
          this.alert_content =
            (error.response && error.response.data) ||
            error.message ||
            error.toString();
          this.alertModal = true;
        });
    },
    changePassword() {
      if (this.new_password2 !== this.changePasswordModel.new_password) {
        let alert_1 = document.querySelector("#alert_1");
        alert_1.classList.remove("d-none");
        alert_1.innerHTML = "Пароли не совпадают";
        return
      }
      this.loading = true;
      UserService.changePassword(this.changePasswordModel).then(
        () => {
          this.loading = false;
          this.changePasswordModal = false;
          this.changePasswordModel = new ChangePassword(null, '', '');
        },
        error => {
          this.loading = false;
          let alert_1 = document.querySelector("#alert_1");
          alert_1.classList.remove("d-none");
          alert_1.innerHTML = "Ошибка смены пароля";
        }
      );
    }
  }
};
</script>
<style>
</style>
