<!-- eslint-disable vue/no-mutating-props -->
<template>
  <card>
    <h5 slot="header" class="title">Карточка интеграции</h5>
    <div class="row">
      <div class="col-md-3">
        <base-input label="Название" placeholder="Название" v-model="model.name">
        </base-input>
      </div>
      <div class="col-md-3">
        <label class="control-label">Организация</label>
        <v-select :searchable="false" :clearable="false" v-on:option:selected="initMoySklad" v-model="model.internal_organization_id" :reduce="organization => organization.id" label="organization" :options="org_list" :disabled="disabled == 1"></v-select>
      </div>
      <div class="col-md-3">
        <label class="control-label">Контрагент</label>
        <v-select :clearable="false" v-model="model.agent" :options="agents" :disabled="disabled == 1"></v-select>
      </div>
      <div class="col-md-3">
        <label class="control-label">Склад</label>
        <v-select :clearable="false" v-model="model.storage" :options="storages" :disabled="disabled == 1"></v-select>
      </div>
      <div class="col-md-2">
        <label class="control-label">Маркетплейс</label>
        <v-select :searchable="false" :clearable="false" v-model="model.market_type" :reduce="market => market.id" :options="markets" :disabled="disabled == 1"></v-select>
      </div>
      <div class="col-md-3">
        <base-input label="Токен" placeholder="Токен" v-model="model.token">
        </base-input>
      </div>
      <div class="col-md-1"  v-if="model.market_type === 2">
        <div class="form-group">
          <label class="control-label">ДБС</label>
          <base-checkbox v-model="model.is_dbs">
          </base-checkbox>
        </div>
      </div>
      <div class="col-md-2" v-if="model.market_type === 2">
        <base-input label="campaign_id магазина в ЯМ" placeholder="campaign_id" v-model="model.additional_id">
        </base-input>
      </div>
      <div class="col-md-2" v-if="model.market_type === 3">
        <base-input label="Client-Id в ozon" placeholder="Client-Id" v-model="model.additional_id">
        </base-input>
      </div>
      <div class="col-md-4" v-if="model.market_type === 1">
        <div class="form-group">
          <label class="control-label">Автоподтверждение в ММ</label>
          <base-checkbox v-model="model.megamarket_auto_approve">
          </base-checkbox>
        </div>
      </div>
    </div>
    <base-button slot="footer" type="primary" fill @click="save">Сохранить</base-button>
    <modal :show.sync="alertModal" class="modal-alert" id="alertModal" :centered="false"
      :show-close="true">
      <div>{{ alert_content }}</div>
      <base-button slot="footer" type="submit" fill @click="alertModal = false" :disabled="loading">Ок</base-button>
      <div class="alert alert-warning alert-dismissible fade show mt-5 d-none" role="alert" id="alert_1">
        <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
      </div>
    </modal>
  </card>
</template>
<script>
import IntegrationService from '../services/integration.service';
import MoyskladService from '../services/moysklad.service';
import OrganizationService from '../services/organization.service';
import Integration from '../models/integration';
import Modal from '@/components/Modal';
import { BaseTable } from "@/components";

export default {
  name: 'integration-card',
  components: {
    Modal,
    BaseTable
  },
  props: {
    id: {
      type: String,
      default: '',
    },
  },
  created() {
  },
  computed: {
  },
  data() {
    return {
      alert_content: '',
      alertModal: false,
      model: new Integration(null, '', '', '', '', '', '', false, false, null),
      markets: [{'id': 1, 'label': 'Мегамаркет'}, {'id': 2, 'label': 'Яндекс Маркет'}, {'id': 3, 'label': 'Ozon'}],
      agents: [],
      storages: [],
      org_list: [],
      disabled: 0
    };
  },
  mounted() {
    this.loadData()
  },
  watch: {
  },
  methods: {
    hasValue(item, column) {
      return item[column.toLowerCase()] !== "undefined";
    },
    initMoySklad() {
      MoyskladService.agents(this.model.internal_organization_id, null).then(
        response => {
          this.agents = response.data
        },
        error => {
          this.alert_content =
            (error.response && error.response.data) ||
            error.message ||
            error.toString();
          this.alertModal = true;
        });
        MoyskladService.storages(this.model.internal_organization_id, null).then(
        response => {
          this.storages = response.data
        },
        error => {
          this.alert_content =
            (error.response && error.response.data) ||
            error.message ||
            error.toString();
          this.alertModal = true;
        });
    },
    loadData() {
      OrganizationService.getBoard().then(
        response => {
          this.org_list = response.data
        },
        error => {
          this.alert_content =
            (error.response && error.response.data) ||
            error.message ||
            error.toString();
          this.alertModal = true;
        }
      )
      if (this.id !== '') {
        this.disabled = 1;
        IntegrationService.get(this.id).then(
          response => {
            this.model = new Integration(this.id, response.data['name'], response.data['token'], response.data['internal_organization_id'], response.data['agent'], response.data['storage'], response.data['additional_id'], response.data['is_dbs'], response.data['megamarket_auto_approve'], response.data['market']);
            this.initMoySklad()
          },
          error => {
            this.alert_content =
              (error.response && error.response.data) ||
              error.message ||
              error.toString();
            this.alertModal = true;
          }
        );
      }
    },
    save() {
      IntegrationService.save(this.model).then(
        response => {
          this.$router.push('/integrations');
        },
        error => {
          this.alert_content =
            (error.response && error.response.data) ||
            error.message ||
            error.toString();
          this.alertModal = true;
        });
    }
  }
};
</script>
<style>
.modal-visitor-edit .modal-content {
  background: #222;
}

</style>
