<template>
  <div>
    <div class="row">
      <div class="col-12">
        <card :title="table.title">
          <base-button slot="footer" type="primary" fill @click="createUser">Создать</base-button>
          <div class="table-responsive">
            <base-table :data="table.data" :columns="table.columns" :actions="table.actions" :editAction="editUser"
              :mobileHideColumns="table.mobileHideColumns" :deleteAction="deleteUser" thead-classes="text-primary">
            </base-table>
          </div>
        </card>
      </div>
    </div>

    <modal :show.sync="alertModal" class="modal-alert" id="alertModal" :centered="false"
      :show-close="true">
      <div>{{ alert_content }}</div>
      <base-button slot="footer" type="submit" fill @click="alertModal = false" :disabled="loading">Ок</base-button>
      <div class="alert alert-warning alert-dismissible fade show mt-5 d-none" role="alert" id="alert_1">
        <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
      </div>
    </modal>
  </div>
  
</template>
<script>
import UserService from '../services/user.service';
import { BaseTable } from "@/components";
import Modal from '@/components/Modal';

export default {
  components: {
    BaseTable,
    Modal
  },
  data() {
    return {
      alert_content: '',
      alertModal: false,
      table: {
        title: "Пользователи",
        columns: ["login"],
        actions: ["edit", "delete"],
        data: []
      },
    };
  },
  mounted() {
    this.loadData();
  },
  methods: {
    loadData() {
      UserService.getBoard().then(
        response => {
          this.table.data = response.data;
        },
        error => {
          this.alert_content =
            (error.response && error.response.data) ||
            error.message ||
            error.toString();
          this.alertModal = true;
        }
      );
    },
    createUser() {
      this.$router.push('/user-create');
    },
    deleteUser: function (id) {
      UserService.delete(id).then(
        response => {
          this.loadData();
        },
        error => {
          this.alert_content =
            (error.response && error.response.data) ||
            error.message ||
            error.toString();
            this.alertModal = true;
        }
      );
    },
    editUser: function (id) {
      this.$router.push('/user-edit/' + id);
    }
  }
};
</script>
<style></style>
