<template>
  <div>
    <div class="row">
    <div class="col-12">
      <card :title="table.title">
        <base-button slot="footer" type="primary" fill @click="createStoreIntegration">Создать</base-button>
        <div class="table-responsive">
          <base-table :data="table.data" :columns="table.columns" :actions="table.actions" :editAction="editStoreIntegration"
            :deleteAction="deleteStoreIntegration" thead-classes="text-primary">
          </base-table>
        </div>
      </card>
    </div>
  </div>

  <modal :show.sync="alertModal" class="modal-alert" id="alertModal" :centered="false"
      :show-close="true">
      <div>{{ alert_content }}</div>
      <base-button slot="footer" type="submit" fill @click="alertModal = false" :disabled="loading">Ок</base-button>
      <div class="alert alert-warning alert-dismissible fade show mt-5 d-none" role="alert" id="alert_1">
        <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
      </div>
    </modal>
  </div>
  
</template>
<script>
import StoreIntegrationService from '../services/store_integration.service';
import { BaseTable } from "@/components";
import Modal from '@/components/Modal';

export default {
  components: {
    BaseTable,
    Modal
  },
  data() {
    return {
      alert_content: '',
      alertModal: false,
      table: {
        title: "Интеграции",
        columns: ["name"],
        actions: ["edit", "delete"],
        data: []
      },
    };
  },
  mounted() {
    StoreIntegrationService.getBoard().then(
      response => {
        this.table.data = response.data;
      },
      error => {
        this.alert_content =
          (error.response && error.response.data) ||
          error.message ||
          error.toString();
        this.alertModal = true;
      }
    );
  },
  methods: {
    createStoreIntegration() {
      this.$router.push('/store-integration-create');
    },
    deleteStoreIntegration: function (id) {
      StoreIntegrationService.delete(id).then(
        response => {
          StoreIntegrationService.getBoard().then(
            response => {
              this.table.data = response.data;
            },
            error => {
              this.alert_content =
                (error.response && error.response.data) ||
                error.message ||
                error.toString();
              this.alertModal = true;
            }
          );
        },
        error => {
          this.alert_content =
            (error.response && error.response.data) ||
            error.message ||
            error.toString();
          this.alertModal = true;
        }
      );
    },
    editStoreIntegration: function (id) {
      this.$router.push('/store-integration-edit/' + id);
    }
  }
};
</script>
<style></style>
