<template>
  <div>
    <div class="row">
    <div class="col-12">
      <card :title="table.title">
        <base-button slot="footer" type="primary" fill @click="createBaseProduct">Создать</base-button>
        <div class="table-responsive">
          <base-table :data="table.data" :idField="table.idField" :columns="table.columns" :actions="table.actions" :editAction="editBaseProduct"
            :deleteAction="deleteBaseProduct" thead-classes="text-primary">
          </base-table>
        </div>
      </card>
    </div>
  </div>
  <modal :show.sync="baseProductModal" class="modal-base-product" id="baseProductModal" :centered="false"
      :show-close="true">
      <base-input label="SKU" placeholder="SKU" v-model="model.sku" :disabled="model.sku !== ''">
        </base-input>
        <base-input label="Название" placeholder="Название" v-model="model.name">
      </base-input>
      <base-input label="Цена" placeholder="Цена" v-model.number="model.price"></base-input>
      <base-input label="Остаток" placeholder="Остаток" v-model="model.amount"></base-input>
      <base-button slot="footer" type="submit" fill @click="saveBaseProduct" :disabled="loading">Сохранить</base-button>
      <div class="alert alert-warning alert-dismissible fade show mt-5 d-none" role="alert" id="alert_1">
        <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
      </div>
    </modal>
    <modal :show.sync="alertModal" class="modal-alert" id="alertModal" :centered="false"
      :show-close="true">
      <div>{{ alert_content }}</div>
      <base-button slot="footer" type="submit" fill @click="alertModal = false" :disabled="loading">Ок</base-button>
      <div class="alert alert-warning alert-dismissible fade show mt-5 d-none" role="alert" id="alert_1">
        <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
      </div>
    </modal>
  </div>
  
</template>
<script>
import BaseProductService from '../services/base_product.service';
import BaseProduct from '../models/base_product';
import Modal from '@/components/Modal';
import { BaseTable } from "@/components";

export default {
  components: {
    Modal,
    BaseTable
  },
  data() {
    return {
      alert_content: '',
      alertModal: false,
      baseProductModal: false,
      loading: false,
      disabled: 0,
      table: {
        idField: "sku",
        title: "Базовые товары",
        columns: ["sku", "name", "price", "amount"],
        actions: ["edit", "delete"],
        data: []
      },
      model: new BaseProduct('', '', 0, 0),
    };
  },
  mounted() {
    BaseProductService.getBoard().then(
      response => {
        this.table.data = response.data;
      },
      error => {
        this.alert_content =
          (error.response && error.response.data['detail']) ||
          error.message ||
          error.toString();
        this.alertModal = true;
      }
    );
  },
  methods: {
    saveBaseProduct() {
      BaseProductService.save(this.model).then(
        response => {
          BaseProductService.getBoard().then(
            response => {
              this.table.data = response.data;
            },
            error => {
              this.alert_content =
                (error.response && error.response.data['detail']) ||
                error.message ||
                error.toString();
              this.alertModal = true;
            }
          );
        },
        error => {
          this.alert_content =
            (error.response && error.response.data['detail']) ||
            error.message ||
            error.toString();
          this.alertModal = true;
        }
      )
      this.baseProductModal = false
    },
    createBaseProduct() {
      this.baseProductModal = true
    },
    deleteBaseProduct: function (id) {
      BaseProductService.delete(id).then(
        response => {
          BaseProductService.getBoard().then(
            response => {
              this.table.data = response.data;
            },
            error => {
              this.alert_content =
                (error.response && error.response.data['detail']) ||
                error.message ||
                error.toString();
              this.alertModal = true;
            }
          );
        },
        error => {
          this.alert_content =
            (error.response && error.response.data['detail']) ||
            error.message ||
            error.toString();
          this.alertModal = true;
        }
      );
    },
    editBaseProduct: function (sku) {
      BaseProductService.get(sku).then(
        response => {
          this.model = new BaseProduct(sku, response.data['name'], response.data['price'], response.data['amount']);
        },
        error => {
          this.alert_content =
            (error.response && error.response.data['detail']) ||
            error.message ||
            error.toString();
          this.alertModal = true;
        }
      );
      this.baseProductModal = true
    }
  }
};
</script>
<style></style>
