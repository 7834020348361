<!-- eslint-disable vue/no-mutating-props -->
<template>
  <card>
    <h5 slot="header" class="title">Карточка организации</h5>
    <div class="row">
      <div class="col-md-5 pr-md-1">
        <base-input label="Токен" placeholder="Токен" v-model="model.token" v-on:focusout="handleBlur" :disabled="disabled == 1">
        </base-input>
      </div>
      <div class="col-md-3 px-md-1">
        <label class="control-label">Организация</label>
        <v-select :clearable="false" placeholder="Выберите организацию" v-model="model.organization" :options="org_list" :disabled="disabled == 1"></v-select>
      </div>
    </div>
    <h5 class="title mt-md-3">Статусы организации</h5>
    <div class="row">
      <div class="col-md-12">
        <label class="control-label">Новый</label>
        <v-select :clearable="false" :searchable="false" placeholder="Выберите статус МС" :reduce="status => status.code" v-model="model.status_model.new" :options="status_list"></v-select>
      </div>
      <div class="col-md-12">
        <label class="control-label">Подтвержден</label>
        <v-select :clearable="false" :searchable="false" placeholder="Выберите статус МС" :reduce="status => status.code" v-model="model.status_model.approved" :options="status_list"></v-select>
      </div>
      <div class="col-md-12">
        <label class="control-label">Собран</label>
        <v-select :clearable="false" :searchable="false" placeholder="Выберите статус МС" :reduce="status => status.code" v-model="model.status_model.packed" :options="status_list"></v-select>
      </div>
      <div class="col-md-12">
        <label class="control-label">Отправлен</label>
        <v-select :clearable="false" :searchable="false" placeholder="Выберите статус МС" :reduce="status => status.code" v-model="model.status_model.sent" :options="status_list"></v-select>
      </div>
      <div class="col-md-12">
        <label class="control-label">Доставлен</label>
        <v-select :clearable="false" :searchable="false" placeholder="Выберите статус МС" :reduce="status => status.code" v-model="model.status_model.delivered" :options="status_list"></v-select>
      </div>
      <div class="col-md-12">
        <label class="control-label">Возврат ожидается</label>
        <v-select :clearable="false" :searchable="false" placeholder="Выберите статус МС" :reduce="status => status.code" v-model="model.status_model.return_awaits" :options="status_list"></v-select>
      </div>
      <div class="col-md-12">
        <label class="control-label">Отменен</label>
        <v-select :clearable="false" :searchable="false" placeholder="Выберите статус МС" :reduce="status => status.code" v-model="model.status_model.canceled" :options="status_list"></v-select>
      </div>
    </div>
    <base-button slot="footer" type="primary" fill @click="save">Сохранить</base-button>
    <modal :show.sync="alertModal" class="modal-alert" id="alertModal" :centered="false"
      :show-close="true">
      <div>{{ alert_content }}</div>
      <base-button slot="footer" type="submit" fill @click="alertModal = false" :disabled="loading">Ок</base-button>
      <div class="alert alert-warning alert-dismissible fade show mt-5 d-none" role="alert" id="alert_1">
        <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
      </div>
    </modal>
  </card>
</template>
<script>
import OrganizationService from '../services/organization.service';
import Organization from '../models/organization';
import StatusModel from '../models/status_model';
import MoyskladService from '../services/moysklad.service';
import Modal from '@/components/Modal';

export default {
  components: {
    Modal
  },
  props: {
    id: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      alert_content: '',
      alertModal: false,
      model: new Organization(null, '', '', new StatusModel('', '', '', '', '', '', '')),
      org_list: [],
      status_list: [],
      disabled: 0
    };
  },
  mounted() {
    if (this.id !== '') {
      this.disabled = 1;
      OrganizationService.get(this.id).then(
        response => {
          this.model = new Organization(this.id, response.data['organization'], response.data['moy_sklad_token'], new StatusModel('', '', '', '', '', '', ''));
          OrganizationService.organizationStatuses(this.id).then(
            response => {
                this.model.status_model = response.data
            },
            error => {
              this.alert_content =
                (error.response && error.response.data) ||
                error.message ||
                error.toString();
              this.alertModal = true;
            }
          )
          this.handleBlur();
        },
        error => {
          this.alert_content =
            (error.response && error.response.data) ||
            error.message ||
            error.toString();
          this.alertModal = true;
        }
      );
    }
  },
  methods: {
    save() {
      OrganizationService.save(this.model).then(
        response => {
          this.$router.push('/organizations');
        },
        error => {
          this.alert_content =
            (error.response && error.response.data) ||
            error.message ||
            error.toString();
          this.alertModal = true;
        });
    },
    handleBlur() {
      MoyskladService.organizations(this.model.token).then(
        response => {
          this.org_list = response.data
        },
        error => {
          this.alert_content =
            (error.response && error.response.data) ||
            error.message ||
            error.toString();
          this.alertModal = true;
        });
        MoyskladService.statuses(this.model.token).then(
        response => {
          this.status_list = []
          response.data.forEach(element => {
            var status = {}
            status.label = element[1]
            status.code = element[0]
            this.status_list.push(status)
          });
        },
        error => {
          this.alert_content =
            (error.response && error.response.data) ||
            error.message ||
            error.toString();
          this.alertModal = true;
        });
    }
  }
};
</script>
<style></style>
