<!-- eslint-disable vue/no-mutating-props -->
<template>
  <card>
    <h5 slot="header" class="title">Карточка базового товара</h5>
    <div class="row">
      <div class="col-md-5 pr-md-1">
        <base-input label="SKU" placeholder="SKU" v-model="model.sku" :disabled="disabled == 1">
        </base-input>
      </div>
      <div class="col-md-3 px-md-1">
        <base-input label="Название" placeholder="Название" v-model="model.name">
        </base-input>
      </div>
      <div class="col-md-3 px-md-1">
        <base-input label="Цена" placeholder="Цена" v-model.number="model.price">
        </base-input>
      </div>
    </div>
    <base-button slot="footer" type="primary" fill @click="save">Сохранить</base-button>
    <modal :show.sync="alertModal" class="modal-alert" id="alertModal" :centered="false"
      :show-close="true">
      <div>{{ alert_content }}</div>
      <base-button slot="footer" type="submit" fill @click="alertModal = false" :disabled="loading">Ок</base-button>
      <div class="alert alert-warning alert-dismissible fade show mt-5 d-none" role="alert" id="alert_1">
        <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
      </div>
    </modal>
  </card>
</template>
<script>
import BaseProductService from '../services/base_product.service';
import BaseProduct from '../models/base_product';
import Modal from '@/components/Modal';

export default {
  components: {
    Modal
  },
  props: {
    sku: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      alert_content: '',
      alertModal: false,
      model: new BaseProduct('', '', 0),
      disabled: 0
    };
  },
  mounted() {
    if (this.sku !== '') {
      this.disabled = 1;
      BaseProductService.get(this.id).then(
        response => {
          this.model = new BaseProduct(this.sku, response.data['name'], response.data['price']);
        },
        error => {
          this.alert_content =
            (error.response && error.response.data['detail']) ||
            error.message ||
            error.toString();
          this.alertModal = true;
        }
      );
    }
  },
  methods: {
    save() {
      BaseProductService.save(this.model).then(
        response => {
          this.$router.push('/base-products');
        },
        error => {
          this.alert_content =
            (error.response && error.response.data['detail']) ||
            error.message ||
            error.toString();
          this.alertModal = true;
        });
    }
  }
};
</script>
<style></style>
