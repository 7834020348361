<template>
  <card>
    <h5 slot="header" class="title">Карточка магазина</h5>
    <div class="row">
      <div class="col-md-5 pr-md-1">
        <base-input label="Название" placeholder="Название" v-model="model.name">
        </base-input>
      </div>
      <div class="col-md-2">
        <base-input label="Доп процент затрат" placeholder="%" v-model="model.additional_expenses">
        </base-input>
      </div>
      <div class="col-md-3">
        <label class="control-label">Финансовая группа</label>
        <v-select :searchable="false" :clearable="false" placeholder="Выберите финансовую группу" v-model="model.finance_group" :reduce="finance_group => finance_group.id" label="name" :options="finance_groups"></v-select>
      </div>
    </div>
    <div class="row">
      <h5 class="title mt-md-3 col-md-12">Желаемая чистая прибыль</h5>
      <div class="col-md-1">
        <base-input label="До 10к" type="number" placeholder="%" v-model="model.store_profit_settings.up_to_ten">
        </base-input>
      </div>
      <div class="col-md-1">
        <base-input label="До 20к" type="number" placeholder="%" v-model="model.store_profit_settings.up_to_twenty">
        </base-input>
      </div>
      <div class="col-md-1">
        <base-input label="До 30к" type="number" placeholder="%" v-model="model.store_profit_settings.up_to_thirty">
        </base-input>
      </div>
      <div class="col-md-1">
        <base-input label="До 40к" type="number" placeholder="%" v-model="model.store_profit_settings.up_to_forty">
        </base-input>
      </div>
      <div class="col-md-1">
        <base-input label="До 50к" type="number" placeholder="%" v-model="model.store_profit_settings.up_to_fifty">
        </base-input>
      </div>
      <div class="col-md-1">
        <base-input label="До 60к" type="number" placeholder="%" v-model="model.store_profit_settings.up_to_sixty">
        </base-input>
      </div>
      <div class="col-md-1">
        <base-input label="До 70к" type="number" placeholder="%" v-model="model.store_profit_settings.up_to_seventy">
        </base-input>
      </div>
      <div class="col-md-1">
        <base-input label="До 80к" type="number" placeholder="%" v-model="model.store_profit_settings.up_to_eighty">
        </base-input>
      </div>
      <div class="col-md-1">
        <base-input label="До 90к" type="number" placeholder="%" v-model="model.store_profit_settings.up_to_ninety">
        </base-input>
      </div>
      <div class="col-md-1">
        <base-input label="До 100к" type="number" placeholder="%" v-model="model.store_profit_settings.up_to_one">
        </base-input>
      </div>
      <div class="col-md-1">
        <base-input label="До 110к" type="number" placeholder="%" v-model="model.store_profit_settings.up_to_one_ten">
        </base-input>
      </div>
      <div class="col-md-1">
        <base-input label="До 120к" type="number" placeholder="%" v-model="model.store_profit_settings.up_to_one_twenty">
        </base-input>
      </div>
      <div class="col-md-1">
        <base-input label="До 130к" type="number" placeholder="%" v-model="model.store_profit_settings.up_to_one_thirty">
        </base-input>
      </div>
      <div class="col-md-1">
        <base-input label="До 140к" type="number" placeholder="%" v-model="model.store_profit_settings.up_to_one_forty">
        </base-input>
      </div>
      <div class="col-md-1">
        <base-input label="До 150к" type="number" placeholder="%" v-model="model.store_profit_settings.up_to_one_fifty">
        </base-input>
      </div>
      <div class="col-md-1">
        <base-input label="Более 150к" type="number" placeholder="%" v-model="model.store_profit_settings.other">
        </base-input>
      </div>
    </div>
    <card class="secondary-card" v-if="model.id === null">
      <h5 slot="header" class="title">Настройки МойСклад</h5>
      <div class="row">
        <div class="col-md-5 pr-md-1">
          <base-input label="Токен" placeholder="Токен" v-model="model.moysklad_organization.token" v-on:focusout="handleBlur" :disabled="disabled == 1">
          </base-input>
        </div>
        <div class="col-md-3 px-md-1">
          <label class="control-label">Организация</label>
          <v-select :clearable="false" placeholder="Выберите организацию" v-model="model.moysklad_organization.organization" :options="org_list" :disabled="disabled == 1"></v-select>
        </div>
      </div>
      <h5 class="title mt-md-3">Статусы организации</h5>
      <div class="row">
        <div class="col-md-12">
          <label class="control-label">Новый</label>
          <v-select :clearable="false" :searchable="false" placeholder="Выберите статус МС" :reduce="status => status.code" v-model="model.moysklad_organization.status_model.new" :options="status_list"></v-select>
        </div>
        <div class="col-md-12">
          <label class="control-label">Подтвержден</label>
          <v-select :clearable="false" :searchable="false" placeholder="Выберите статус МС" :reduce="status => status.code" v-model="model.moysklad_organization.status_model.approved" :options="status_list"></v-select>
        </div>
        <div class="col-md-12">
          <label class="control-label">Собран</label>
          <v-select :clearable="false" :searchable="false" placeholder="Выберите статус МС" :reduce="status => status.code" v-model="model.moysklad_organization.status_model.packed" :options="status_list"></v-select>
        </div>
        <div class="col-md-12">
          <label class="control-label">Отправлен</label>
          <v-select :clearable="false" :searchable="false" placeholder="Выберите статус МС" :reduce="status => status.code" v-model="model.moysklad_organization.status_model.sent" :options="status_list"></v-select>
        </div>
        <div class="col-md-12">
          <label class="control-label">Доставлен</label>
          <v-select :clearable="false" :searchable="false" placeholder="Выберите статус МС" :reduce="status => status.code" v-model="model.moysklad_organization.status_model.delivered" :options="status_list"></v-select>
        </div>
        <div class="col-md-12">
          <label class="control-label">Возврат ожидается</label>
          <v-select :clearable="false" :searchable="false" placeholder="Выберите статус МС" :reduce="status => status.code" v-model="model.moysklad_organization.status_model.return_awaits" :options="status_list"></v-select>
        </div>
        <div class="col-md-12">
          <label class="control-label">Отменен</label>
          <v-select :clearable="false" :searchable="false" placeholder="Выберите статус МС" :reduce="status => status.code" v-model="model.moysklad_organization.status_model.canceled" :options="status_list"></v-select>
        </div>
      </div>
    </card>
    <base-button type="primary" fill @click="save">Сохранить</base-button>
    <card :title="Товары" v-if="model.id !== null">
      <div class="btn-group btn-group-toggle" data-toggle="buttons">
        <label
          :key="product"
          class="btn btn-sm btn-primary btn-simple"
          :class="{ active: showStoreProductTable }"
          :id="0">
          <input
            type="radio"
            @click="clickMenu(0)"
            name="options"
            autocomplete="off"
            :checked="showStoreProductTable"
          />
          Товары
        </label>
        <label
          :key="integration"
          class="btn btn-sm btn-primary btn-simple"
          :class="{ active: showIntegrationTable }"
          :id="1"
        >
          <input
            type="radio"
            @click="clickMenu(1)"
            name="options"
            autocomplete="off"
            :checked="showIntegrationTable"
          />
          Интеграции
        </label>
      </div>
      <div class="table-responsive" v-if="showStoreProductTable">
        <base-table :has_next="has_next_page" :with_filter="true" :reloadAction="loadStoreProducts" :page="page" :use_paging="use_paging" :data="store_product_table.data" :columns="store_product_table.columns" :actions="store_product_table.actions" :editAction="editStoreProduct"
          thead-classes="text-primary">
        </base-table>
      </div>
      <div class="table-responsive" v-if="showIntegrationTable">
        <base-table :with_num_filter="true" :color_columns="integration_product_table.color_columns" :has_next="has_next_page" :with_filter="true" :reloadAction="loadStoreProducts" :page="page" :use_paging="use_paging"  :additional_columns="integration_product_table.additional_columns" :additional_columns_name="integration_product_table.additional_columns_name" :additional_columns_with_action="integration_product_table.additional_columns_with_action" :data="integration_product_table.data" :columns="integration_product_table.columns" :actions="integration_product_table.actions" :additional_column_edit_action="editIntegrationProduct"
          thead-classes="text-primary">
        </base-table>
      </div>
    </card>
    <modal :show.sync="storeProductModal" class="modal-store-product" id="storeProductModal" :centered="false"
      :show-close="true">
      <base-input label="SKU" placeholder="SKU" v-model="store_product_model.sku">
        </base-input>
      <div class="form-group">
        <label class="control-label">Активность</label>
        <base-checkbox v-model="store_product_model.active">
        </base-checkbox>
      </div>
      <base-button slot="footer" type="submit" fill @click="saveStoreProduct" :disabled="loading">Сохранить</base-button>
      <div class="alert alert-warning alert-dismissible fade show mt-5 d-none" role="alert" id="alert_1">
        <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
      </div>
    </modal>
    <modal :show.sync="alertModal" class="modal-alert" id="alertModal" :centered="false"
      :show-close="true">
      <div>{{ alert_content }}</div>
      <base-button slot="footer" type="submit" fill @click="alertModal = false" :disabled="loading">Ок</base-button>
      <div class="alert alert-warning alert-dismissible fade show mt-5 d-none" role="alert" id="alert_1">
        <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
      </div>
    </modal>
    <modal :show.sync="integrationProductModal" class="modal-integration-product" id="integrationProductModal" :centered="false"
      :show-close="true">
      <base-input label="Базовый SKU" v-model="integration_product_model.base_sku" disabled>
        </base-input>
      <base-input label="SKU" v-model="integration_product_model.sku" disabled>
        </base-input>
      <base-input label="Закупочная цена" v-model="integration_product_model.base_price" disabled>
        </base-input>
      <base-input label="Цена" v-model="integration_product_model.price">
        </base-input>
      <base-input label="Затраты" v-model="integration_product_model.commission" disabled>
        </base-input>
      <base-input label="Прибыль" :value="integration_product_model.price-integration_product_model.commission-integration_product_model.base_price" disabled>
        </base-input>
      <base-input label="Прибыль %" v-if="integration_product_model.base_price > 0" :value="((integration_product_model.price-integration_product_model.commission-integration_product_model.base_price) / integration_product_model.base_price * 100).toFixed(2)" disabled>
      </base-input>
      <base-input label="Прибыль %" v-else :value="0" disabled>
      </base-input>
      <base-button slot="footer" fill @click="recalculateCommission" :disabled="loading">Пересчитать коммиссию</base-button>
      <base-button slot="footer" fill @click="recalculatePrice" :disabled="loading">Пересчитать цену от базовой</base-button>
      <base-button slot="footer" type="submit" fill @click="saveIntegrationProduct" :disabled="loading">Сохранить</base-button>
      <div class="alert alert-warning alert-dismissible fade show mt-5 d-none" role="alert" id="alert_1">
        <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
      </div>
    </modal>
    <div class="lock" v-if="loading">
      <div class="loader"></div>
    </div>
    
  </card>
</template>
<script>
import MoyskladService from '../services/moysklad.service';
import Organization from '../models/organization';
import FinanceGroupService from '../services/finance_group.service';
import StoreService from '../services/store.service';
import ProductService from '../services/product.service';
import Store from '../models/store';
import StoreProduct from '../models/store_product';
import IntegrationProductModel from '../models/integration_product';
import StatusModel from '../models/status_model';
import StoreProfitSettings from '../models/store_profit_settings';
import Modal from '@/components/Modal';
import { BaseTable } from "@/components";
import Paging from '../models/paging';
import Filter from '../models/filter';

export default {
  components: {
    Modal,
    BaseTable
  },
  props: {
    id: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      loading: false,
      alert_content: '',
      alertModal: false,
      sku_filter: '',
      storeProductModal: false,
      showStoreProductTable: true,
      integrationProductModal: false,
      showIntegrationTable: false,
      model: new Store(null, '', 0, false, null, new Organization(null, '', '', new StatusModel('', '', '', '', '', '', '')), new StoreProfitSettings(0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0)),
      store_product_model: new StoreProduct(null, null, true),
      integration_product_model: new IntegrationProductModel(null, null, null, null, null, null, null),
      disabled: 0,
      finance_groups: [],
      org_list: [],
      agents: [],
      storages: [],
      status_list: [],
      page: 1,
      base_price_filter: false,
      use_paging: true,
      has_next_page: false,
      store_product_table: {
        idField: "id",
        title: "Товары магазина",
        columns: ["base_sku", "sku", "name", "active"],
        actions: ["edit"],
        data: [],
      },
      integration_product_table: {
        idField: "id",
        title: "Товары интеграций",
        columns: ["base_sku", "sku", "name", "base_price"],
        actions: [],
        additional_columns: [],
        additional_columns_name: [],
        additional_columns_with_action: [],
        data: [],
        color_columns: []
      },
    };
  },
  mounted() {
    FinanceGroupService.getBoard().then(
      response => {
        this.finance_groups = response.data;
      },
      error => {
        this.alert_content =
          (error.response && error.response.data['detail']) ||
          error.message ||
          error.toString();
        this.alertModal = true;
      }
    );
    if (this.id !== '') {
      this.disabled = 1;
      StoreService.get(this.id).then(
        response => {
          this.model.id = response.data['id']
          this.model.name = response.data['name']
          this.model.additional_expenses = response.data['additional_expenses']
          this.model.finance_group = response.data['finance_group']
          this.model.store_profit_settings.up_to_ten = response.data['store_profit_settings']['up_to_ten']
          this.model.store_profit_settings.up_to_twenty = response.data['store_profit_settings']['up_to_twenty']
          this.model.store_profit_settings.up_to_thirty = response.data['store_profit_settings']['up_to_thirty']
          this.model.store_profit_settings.up_to_forty = response.data['store_profit_settings']['up_to_forty']
          this.model.store_profit_settings.up_to_fifty = response.data['store_profit_settings']['up_to_fifty']
          this.model.store_profit_settings.up_to_sixty = response.data['store_profit_settings']['up_to_sixty']
          this.model.store_profit_settings.up_to_seventy = response.data['store_profit_settings']['up_to_seventy']
          this.model.store_profit_settings.up_to_eighty = response.data['store_profit_settings']['up_to_eighty']
          this.model.store_profit_settings.up_to_ninety = response.data['store_profit_settings']['up_to_ninety']
          this.model.store_profit_settings.up_to_one = response.data['store_profit_settings']['up_to_one']
          this.model.store_profit_settings.up_to_one_ten = response.data['store_profit_settings']['up_to_one_ten']
          this.model.store_profit_settings.up_to_one_twenty = response.data['store_profit_settings']['up_to_one_twenty']
          this.model.store_profit_settings.up_to_one_thirty = response.data['store_profit_settings']['up_to_one_thirty']
          this.model.store_profit_settings.up_to_one_forty = response.data['store_profit_settings']['up_to_one_forty']
          this.model.store_profit_settings.up_to_one_fifty = response.data['store_profit_settings']['up_to_one_fifty']
          this.model.store_profit_settings.other = response.data['store_profit_settings']['other']
        },
        error => {
          this.alert_content =
            (error.response && error.response.data['detail']) ||
            error.message ||
            error.toString();
          this.alertModal = true;
        }
      );
      this.loadStoreProducts(1, this.sku_filter, this.base_price_filter);
    }
  },
  methods: {
    recalculatePrice() {
      ProductService.recalculate_profit_price(this.integration_product_model.integration_id, this.integration_product_model.id).then(
        response => {
          this.integrationProductModal = false
          this.loadStoreProducts(this.page, this.sku_filter, this.base_price_filter)
        },
        error => {
          this.alert_content =
            (error.response && error.response.data['detail']) ||
            error.message ||
            error.toString();
          this.alertModal = true;
        }
      );
    },
    loadStoreProducts(page, search_filter, base_price_filter) {
      ProductService.getBoard(this.id, new Paging(page, 50, new Filter(search_filter, base_price_filter))).then(
        response => {
          this.sku_filter = search_filter
          this.base_price_filter = base_price_filter
          this.integration_product_table = {
            idField: "id",
            title: "Товары интеграций",
            columns: ["base_sku", "sku", "name", "base_price"],
            actions: [],
            additional_columns: [],
            additional_columns_name: [],
            additional_columns_with_action: [],
            data: [],
            color_columns: {}
          }
          this.page = response.data['page_num']
          this.has_next_page = response.data['has_next']
          this.store_product_table.data = response.data['data'];
          if (this.store_product_table.data[0].integration_prices !== undefined) {
            let integrations = this.store_product_table.data[0].integration_prices
            for (const integration of integrations) {
              this.integration_product_table.additional_columns.push("price" + integration.integration_id)
              this.integration_product_table.additional_columns_with_action.push("price" + integration.integration_id)
              this.integration_product_table.additional_columns.push("commission" + integration.integration_id)
              this.integration_product_table.additional_columns.push("profit" + integration.integration_id)
              this.integration_product_table.additional_columns_name.push(integration.integration_name + '<pre style="margin-bottom:0px">Цена</pre>')
              this.integration_product_table.additional_columns_name.push("Затраты")
              this.integration_product_table.additional_columns_name.push("Прибыль %")
              this.integration_product_table.color_columns["price" + integration.integration_id] = "hand_set" + integration.integration_id
            }
            for (const store_product of this.store_product_table.data) {
              if (store_product.sku === '' || store_product.sku === null || store_product.sku === undefined) {
                continue
              }
              let item = {}
              item.id = store_product.id
              item.base_sku = store_product.base_sku
              item.sku = store_product.sku
              item.name = store_product.name
              item.base_price = store_product.base_price
              let integrations = store_product.integration_prices
              for (const integration of integrations) {
                item["price" + integration.integration_id] = integration.price
                item["commission" + integration.integration_id] = integration.commission
                if (store_product.base_price > 0) {
                  item["profit" + integration.integration_id] = ((integration.price - integration.commission - store_product.base_price) / store_product.base_price * 100).toFixed(2)
                } else {
                  item["profit" + integration.integration_id] = 0
                }
                item.integration_id = integration.integration_id
                item["hand_set" + integration.integration_id] = integration.hand_set
              }
              this.integration_product_table.data.push(item)
            }
          }
        },
        error => {
          this.alert_content =
            (error.response && error.response.data['detail']) ||
            error.message ||
            error.toString();
          this.alertModal = true;
        }
      );
    },
    clickMenu(index) {
      if (index === 0) {
        this.showStoreProductTable = true
        this.showIntegrationTable = false
      } else if (index === 1) {
        this.showStoreProductTable = false
        this.showIntegrationTable = true
      }
    },
    save() {
      this.loading = true;
      StoreService.save(this.model).then(
        response => {
          this.$router.push('/stores');
        },
        error => {
          this.loading = false;
          this.alert_content =
            (error.response && error.response.data['detail']) ||
            error.message ||
            error.toString();
          this.alertModal = true;
        });
    },
    saveStoreProduct() {
      ProductService.save_store_product(this.model.id, this.store_product_model).then(
        response => {
          this.storeProductModal = false
          this.loadStoreProducts(this.page, this.sku_filter, this.base_price_filter)
        },
        error => {
          this.alert_content =
            (error.response && error.response.data['detail']) ||
            error.message ||
            error.toString();
          this.alertModal = true;
        }
      );
    },
    saveIntegrationProduct() {
      ProductService.save(this.integration_product_model.integration_id, this.integration_product_model).then(
        response => {
          this.integrationProductModal = false
          this.loadStoreProducts(this.page, this.sku_filter, this.base_price_filter)
        },
        error => {
          this.alert_content =
            (error.response && error.response.data['detail']) ||
            error.message ||
            error.toString();
          this.alertModal = true;
        }
      );
    },
    recalculateCommission() {
      ProductService.product_commission(this.integration_product_model.integration_id, this.integration_product_model).then(
        response => {
          this.integration_product_model.commission = response.data.commission
        },
        error => {
          this.alert_content =
            (error.response && error.response.data['detail']) ||
            error.message ||
            error.toString();
          this.alertModal = true;
        }
      );
    },
    editStoreProduct: function (id) {
      ProductService.get(this.model.id, id).then(
        response => {
          this.store_product_model = new StoreProduct(id, response.data['sku'], response.data['active']);
        },
        error => {
          this.alert_content =
            (error.response && error.response.data['detail']) ||
            error.message ||
            error.toString();
          this.alertModal = true;
        }
      );
      this.storeProductModal = true
    },
    editIntegrationProduct: function (item) {
      this.integration_product_model = new IntegrationProductModel(item.id, item.sku, item.base_sku, item.base_price, item['price'+item.integration_id], item['commission'+item.integration_id], item.integration_id);
      this.integrationProductModal = true
    },
    handleBlur() {
      MoyskladService.organizations(this.model.moysklad_organization.token).then(
        response => {
          this.org_list = response.data
        },
        error => {
          this.alert_content =
            (error.response && error.response.data['detail']) ||
            error.message ||
            error.toString();
          this.alertModal = true;
        });
        MoyskladService.statuses(this.model.moysklad_organization.token).then(
          response => {
            this.status_list = []
            response.data.forEach(element => {
              var status = {}
              status.label = element[1]
              status.code = element[0]
              this.status_list.push(status)
            });
          },
          error => {
            this.alert_content =
              (error.response && error.response.data['detail']) ||
              error.message ||
              error.toString();
            this.alertModal = true;
          });
        MoyskladService.agents(null, this.model.moysklad_organization.token).then(
          response => {
            this.agents = response.data
          },
          error => {
            this.alert_content =
              (error.response && error.response.data['detail']) ||
              error.message ||
              error.toString();
            this.alertModal = true;
          });
        MoyskladService.storages(null, this.model.moysklad_organization.token).then(
          response => {
            this.storages = response.data
          },
          error => {
            this.alert_content =
              (error.response && error.response.data['detail']) ||
              error.message ||
              error.toString();
            this.alertModal = true;
          });
    }
  }
};
</script>
<style>
.form-check .form-check-label {
      margin-bottom: 5px;
      display: block;
}
.form-check {
    padding-left: 1.25rem;
}
.check-group {
  display: flex;
}
.secondary-card{
  box-shadow: none;
}
</style>
