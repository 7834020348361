<!-- eslint-disable vue/no-mutating-props -->
<template>
  <card>
    <h5 slot="header" class="title">Карточка финансовой группы</h5>
    <div class="row">
      <div class="col-md-5 pr-md-1">
        <base-input label="Название" placeholder="Название" v-model="model.name">
        </base-input>
      </div>
      <div class="col-md-5 pr-md-1">
        <base-input type="number" label="Остаток денег на руках" placeholder="Остаток денег на руках" v-model.number="model.money">
        </base-input>
      </div>
    </div>
    <base-button slot="footer" type="primary" fill @click="save">Сохранить</base-button>
    <modal :show.sync="alertModal" class="modal-alert" id="alertModal" :centered="false"
      :show-close="true">
      <div>{{ alert_content }}</div>
      <base-button slot="footer" type="submit" fill @click="alertModal = false" :disabled="loading">Ок</base-button>
      <div class="alert alert-warning alert-dismissible fade show mt-5 d-none" role="alert" id="alert_1">
        <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
      </div>
    </modal>
  </card>
</template>
<script>
import FinanceGroup from '../models/finance_group';
import FinanceGroupService from '../services/finance_group.service';
import Modal from '@/components/Modal';

export default {
  components: {
    Modal
  },
  props: {
    id: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      alert_content: '',
      alertModal: false,
      model: new FinanceGroup(null, '', 0),
    };
  },
  mounted() {
    if (this.id !== '') {
      this.disabled = 1;
      FinanceGroupService.get(this.id).then(
        response => {
          this.model.id = response.data['id']
          this.model.name = response.data['name']
          this.model.money = response.data['money']
        },
        error => {
          this.alert_content =
            (error.response && error.response.data['detail']) ||
            error.message ||
            error.toString();
          this.alertModal = true;
        }
      );
    }
  },
  methods: {
    save() {
      FinanceGroupService.save(this.model).then(
        response => {
          this.$router.push('/finance-groups');
        },
        error => {
          this.alert_content =
            (error.response && error.response.data['detail']) ||
            error.message ||
            error.toString();
          this.alertModal = true;
        });
    },
  }
};
</script>
<style>
.form-check .form-check-label {
      margin-bottom: 5px;
      display: block;
}
.form-check {
    padding-left: 1.25rem;
}
.check-group {
  display: flex;
}
.secondary-card{
  box-shadow: none;
}
</style>
